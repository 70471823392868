// development
// export const API_URL = "http://public.test/api";
// export const OCCUPANTS_URL = "http://localhost:3001";
// export const ADMIN_URL = "http://localhost:3000";
// export const COBEE_VERSION = "6.2.0";

// development
// export const API_URL = "https://api.cobee.maartenkoopmans.nl/api";
// export const OCCUPANTS_URL = "https://maartenkoopmans.nl:3001";
// export const ADMIN_URL = "https://maartenkoopmans.nl:3000";
// export const COBEE_VERSION = "6.2.0";

// staging
export const API_URL = "https://api.staging.cobee.nl/api";
export const OCCUPANTS_URL = "https://bewoners.staging.cobee.nl";
export const ADMIN_URL = "https://beheer.staging.cobee.nl";
export const COBEE_VERSION = "6.2.0";

// production
// export const API_URL = "https://api.cobee.nl/api";
// export const OCCUPANTS_URL = "https://bewoners.cobee.nl";
// export const ADMIN_URL = "https://beheer.cobee.nl";
// export const COBEE_VERSION = "6.2.0";
